<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :to="createBotRoute"
        variant="primary"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
  </div>
</template>

<script>
import { VxTable, VxCellButtons, VxCellBadges, VxCellBotLastActiveTime } from '@/components/table'
import {
  botEnvs,
  botPurposes,
  bots,
  botStatuses,
  passDataToResource
} from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { filterTypes, sortOrders } from '@/services/table'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'

export default {
  name: 'Bots',
  components: {
    VxTable,
    VxButton,
    VxCellButtons,
    VxCellBotLastActiveTime,
    VxPageTitleActions
  },
  setup () {
    const resource = bots.getAll

    const createBotRoute = path.createBot

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      { key: 'name' },
      { key: 'env' },
      { key: 'purpose' },
      {
        key: 'status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [botStatuses.active]: 'success',
            [botStatuses.inactive]: 'danger'
          }
        }
      },
      {
        key: 'last_active_at',
        label: 'Last Active',
        component: VxCellBotLastActiveTime
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'name',
        type: filterTypes.text
      },
      {
        key: 'env',
        type: filterTypes.select,
        options: Object.values(botEnvs)
      },
      {
        key: 'purpose',
        type: filterTypes.select,
        options: Object.values(botPurposes)
      }
    ]

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.view,
        to: buildRoute(path.updateBot, { params: { id } })
      }
      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(
          bots.delete,
          {
            requestParams: { urlParams: { id } }
          }
        )
      }

      return [editButton, deleteButton]
    }

    return {
      resource,
      columns,
      filters,

      createBotRoute
    }
  }
}
</script>
